import _ from 'lodash'
export const mixins = {
  data() {
    return {
      chartsTheme: require('@/const/macarons.json'), // 圖表主題
      purchaseNumOptions: [], // 採購編號列表
      contractNumOptions: [], // 契約編號列表
      manufacturerOptions: [], // 廠商列表
      fanOptions: [], // 傳輸模組列表
      monitorOptions: [], // 監測模組列表
      waterIdOptions: [], // 用水編號列表
      meterNumOptions: [], // 水表表號列表
      waterNumOptions: [], // 用戶水號列表
      accountOptions: [], // 使用者帳號列表
      unitRuleOptions: [], // 分處規則列表
      meterReplacementGroupOptions: [], // 水栓群組列表
      debounceTimeout: null, // debounce timeout
      waterNameOptions: [], // 用戶名稱
    }
  },
  methods: {
    // dialog 寬度設定
    setDialogWidth() { // dialog responsive
      let val = document.body.clientWidth
      let dialogWidth
      const def = 1300 // 默認寬度
      if (val < def) {
        dialogWidth = '90%'
      } else {
        dialogWidth = def + 'px'
      }
      return dialogWidth
    },
    // 日期格式 yyyy-MM-DD
    parseDateTime(datetime) {
      if (datetime) {
        let dt = this.$moment(datetime).tz('Asia/Taipei').format() // 轉為台灣時區
        return dt.replace('T',' ').substring(0,10)
      }
    },
    // 日期格式 yyyy-MM-DD hh:mm:ss
    parseDateTimeToHHMMSS(datetime) {
      if (datetime) {
        let dt = this.$moment(datetime).tz('Asia/Taipei').format() // 轉為台灣時區
        return dt.replace('T',' ').substring(0,19)
      }
    },
    // 日期格式 (無TZ設定)
    parseDateTimeToHHMMSSWithoutTz(datetime) {
      if (datetime) {
        return datetime.replace('T',' ').substring(0,19)
      }
    },
    // 日期格式 hh:mm:ss
    parseDateTimeToOnlyTime(datetime) {
      if (datetime) {
        let dt = this.$moment(datetime).tz('Asia/Taipei').format() // 轉為台灣時區
        return dt.replace('T',' ').substring(11,19)
      }
    },

    // 日期格式 hh:mm
    parseDateTimeToOnlyHHMM(datetime) {
      if (datetime) {
        let dt = this.$moment(datetime).tz('Asia/Taipei').format() // 轉為台灣時區
        return dt.replace('T',' ').substring(11,16)
      }
    },
    /* N days ago
      date 可為 Date()
      return yyyy-MM-dd
    */
    getNDaysAgo(N, date) {
      const daysAgo = new Date(date.getTime())
      daysAgo.setDate(date.getDate() - N)
      return daysAgo.toISOString().substring(0,10)
    },
    /* N days after
      date 可為 Date()
      return yyyy-MM-dd
    */
    getNDaysAfter(N, date) {
      const daysAfter = new Date(date)
      daysAfter.setDate(daysAfter.getDate() + N)
      return daysAfter.toISOString().substring(0,10)
    },
    // table排序
    tableSortChange(column) { // 自定義排序方式
      if (column.prop.indexOf('profile.') != -1) {
        column.prop = column.prop.replace('profile.','')
      }
      if (column.order === 'descending') {
        this.listQuery.sort = column.prop + ',' + 'DESC'
      } else {
        this.listQuery.sort = column.prop + ',' + 'ASC'
      }
      this.getList()
    },
    tableSortChangeNotReplace(column) { // 自定義排序方式
      // if (column.prop.indexOf('profile.') != -1) {
      //   column.prop = column.prop.replace('profile.','')
      // }
      if (column.order === 'descending') {
        this.listQuery.sort = column.prop + ',' + 'DESC'
      } else {
        this.listQuery.sort = column.prop + ',' + 'ASC'
      }
      this.getList()
    },
    logOperate(type, path, action, event) {
      let op = {
        "type": type,
        "uri": path,
        "action": action,
        "event": event
      }
      this.axios.post('/api/v1/logs', op).then(() => {
        //
      }).catch(err => {
        //
      })
    },

    // 匯出檔案
    exportFile(queryUrl, queryParams) {
      this.axios.get(queryUrl,  {params: queryParams, responseType: 'blob'}).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a') // 利用<a>標籤下載
        link.style.display = 'none'
        link.href = url
        const fileName = decodeURI(response.headers['content-disposition'].split(';')[1].split('=')[1])
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
    },

    // 重設查詢頁數
    resetPage(fn) {
      this.paginationSelectedPage = 1
      this.listQuery.page = 0
      fn()
    },

    // 重設查詢頁數
    resetOcpiPage(fn) {
      this.paginationSelectedPage = 1
      this.listQuery.page = 0
      fn()
    },
    // debounce getList 無分頁
    debounceGetListWithoutPage(fn, delay) {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout)
      }

      this.debounceTimeout = setTimeout(() => {
        fn()
      }, delay)
    },

    // 計算日期差
    calculateDateDiff(startDateStr, endDateStr) {
      const startDate = new Date(startDateStr);
      const endDate = new Date(endDateStr);

      // 將時間部分置為 00:00:00，以計算整天的差距
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(0, 0, 0, 0);

      const timeDifference = endDate - startDate;
      const dayDifference = timeDifference / (1000 * 60 * 60 * 24);

      return dayDifference;
    },

    // 交易紀錄
    diffTime(stopTime, startTime) {
      let stop_date = new Date(stopTime);
      let start_date = new Date(startTime);
      if(stop_date < start_date) {
        return "ERR";
      }
      let secs = (stop_date - start_date) / 1000;
      let hr = Math.floor(secs / 3600);
      let min = Math.floor((secs - (hr * 3600)) / 60);
      let sec = parseInt(secs - (hr * 3600) - (min * 60));

      while (min.length < 2) { min = '0' + min; }
      while (sec.length < 2) { sec = '0' + sec; }
      if (hr) hr += '時';
      return hr + min + '分' + (sec > 0 ? sec + '秒' : '');
    },
    diffMetter(stopMetter, startMetter) {
      if(stopMetter < startMetter) {
        return "ERR";
      }
      return (stopMetter - startMetter)/1000;

    },
    formatCurrentDateTime() {
      let now = new Date();
      let year = now.getFullYear();
      let month = ('0' + (now.getMonth() + 1)).slice(-2);
      let day = ('0' + now.getDate()).slice(-2);
      let hours = ('0' + now.getHours()).slice(-2);
      let minutes = ('0' + now.getMinutes()).slice(-2);
      let seconds = ('0' + now.getUTCSeconds()).slice(-2);
      return year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds
    },
    formatSecond(seconds) {
      let result = [];
      let count = 2;
      while (count >= 0) {
        let current = Math.floor(seconds / (60 ** count));
        result.push(current);
        seconds -= current * (60 ** count);
        --count;
      }
      return result.map(item => item <= 9 ? `0${item}` : item).join(":")
    },
    formatPayType(type) {
      if(type == 1) {
        return this.$t('CreditCard')
      } else if(type == 2) {
        return "LINE Pay"
      }
      return type
    },
    formatChargingStatus(status){
      if (status == "proccessing_preparing") status = "準備中" //  已插槍，等待遠端啟動或等待 RFID 刷卡認證(" + status + ")"
      else if (status == "proccessing_remote_start_sending") status = "遠端充電發送中"
      else if (status == "proccessing_remote_start_accepted") status = "遠端充電已接受"
      //else if (status == "proccessing_rfid_accepted") status = "RFID 認證已通過，等待插槍中"
      else if (status == "charging") status = "充電中"
      else if (status == "proccessing_remote_stop_sending") status = "遠端停止發送中"
      else if (status == "proccessing_remote_stop_accepted") status = "遠端停止已接受"
      //else if (status == "proccessing_remote_stop_rejected") status = "proccessing_remote_stop_rejected"
      else if (status == "proccessing_bill_start") status = "充電完成，付款中"
      else if (status == "completed") status = "充電完成"
      else if (status == "failed") status = "失敗"
      else if (status == "failed_remote_start_rejected") status = "失敗: 遠端充電被拒絕"
      else if (status == "failed_timeout") status = "失敗: 插槍逾時"
      else if (status == "failed_reason2") status = "錯誤(保留欄位)"
      else if (status == "pending") status = "擱置中(pending)"
      else if (status == "active") status ="運作中(active)"
      else if (status == "invalid") status ="無效(invalid)"
      else if (status == "reservation") status ="預約中(reservation)"

      return status;
    },
    // 自定義表格Header,例如：給表頭加一個icon，滑鼠移入icon顯示隱碼規則提示文字
    renderAccountHeaderMethods(h, {column}) {
      return h(
          'div', {
            style: 'display:inline-flex;margin:auto;'
          },
          [
            h('span', column.label),
            h('promptMessages', {
              props: {
                messages: [
                  '隱碼規則：隱藏後4碼'
                ]
              }
            })
          ]
      )
    },
    renderNameHeaderMethods(h, {column}) {
      return h(
          'div', {
            style: 'display:inline-flex;margin:auto;'
          },
          [
            h('span', column.label),
            h('promptMessages', {
              props: {
                messages: [
                  '隱碼規則：顯示第一個字元，其餘皆隱碼'
                ]
              }
            })
          ]
      )
    },
    renderEmailHeaderMethods(h, {column}) {
      return h(
          'div', {
            style: 'display:inline-flex;margin:auto;'
          },
          [
            h('span', column.label),
            h('promptMessages', {
              props: {
                messages: [
                  '隱碼規則：顯示前4碼,不足補e, 其後不論字元數皆隱碼，並隱藏第一區以@***取代)'
                ]
              }
            })
          ]
      )
    },
    //個資隱藏-姓名,處理規則:顯示第一字元不論幾碼皆加**,ex:劉德華-->劉**,歐陽菲菲-->歐***
    hideName(name){
      let nameHide = "";
      if(name!==null)
      {
        let nameArr = name.split('');

        //console.log(nameArr);
        nameArr.map((res,index)=> {
          if(index>0){
            nameHide += '*';
          }
          else{
            nameHide += res;
          }
        });
        return nameHide;
      }

    },
    //個資隱藏-Email,處理規則:前4碼,不足補e, 其後不論字元數皆以*取代 (隱藏第一區以@***取代),ex:jaye*****@***.gov.tw
    hideEmail(email){
      let emailHide = "";
      if(email!== null) {
        let emailArr = email.split('');
        let indexOfAt = email.indexOf('@');
        let idexOfFirstDot = email.indexOf('.');
        let e_charlist = "";
        let counter = 0;

        //@之前的字元若不足4個，缺少的字元以添加'e'填補,s@--> seee@
        if(indexOfAt <= 3)
        {
          counter = indexOfAt;
          while(counter <= 3 )
          {
            e_charlist += 'e';
            counter ++;
          }
        }

        //console.log(nameArr);
        emailArr.map((res, index) => {
          //處理email字串中第一個點'.'之前的字元, ex: s@com.tw字串的s@com
          if (index < idexOfFirstDot) {

            //@之前的字元若不足4個，缺少的字元以添加'e'填補,@之後的字元以'*'取代, ex: s@com --> seee@***
            if(indexOfAt <= 3)
            {
              //s@-->s@
              if(index < indexOfAt )
              {
                emailHide += res;
              }

              //s@-->seee@
              else if(index == indexOfAt)
              {

                   emailHide += e_charlist+'@';
              }
              //s@com-->seee@***
              else {
                emailHide += '*';
              }
            }
            //@之前的字元若大於等於4個字元,則第5個字元開始只要不是@的字元就以'*'取代, ex:taiyu@com-->taiy*@***
            else
            {
              if(index <= 3 )
              {
                emailHide += res;
              }
              else if (index == indexOfAt) {
                emailHide += '@';
              }
              else {
                emailHide +='*';
              }
            }
          }
          //email字串中第一個點'.'之前的字元則正常顯示
          else{

            emailHide += res;
          }

        });
        return emailHide;
      }
    },
  }
}

